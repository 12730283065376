<template>
  <div class="footer-content">
    <div class="footer-info">
      <div class="footerL">
        <div class="point"></div>
        <div class="footerL-item">
          <div class="footerL-1">
            <div class="info">欢迎关注沧穹科技官方微信公众号发送留言洽谈合作</div>
            <div class="info">如有紧急需求可以联系客服（TEL:4009684123）</div>
          </div>
          <div class="footerL-2"><img src="/images/wx.jpg" style="width: 87px; height: 87px" /></div>
        </div>
      </div>
      <div class="footerC"></div>
      <div class="footerR">
        <div class="point"></div>
        <div class="footerR-item">
          <div class="info"><i class="el-icon-location-outline"></i> &nbsp;地址：广东省深圳市南山区粤海街道海珠社区海德三道1066号深创投广场3803</div>
          <div class="info"><i class="el-icon-message"></i>&nbsp;邮箱：wenjing.gong@zlnavi.com</div>
        </div>
      </div>
    </div>
    <div class="copyright">Copyright&copy; 2020-2024 深圳沧穹科技有限公司
	    <span onclick="javascript:window.open('https://beian.miit.gov.cn')" style="text-decoration:underline; cursor:pointer;margin-left: 20px;">粤ICP备2023070565号</span>
	    <span data-v-8afd4414="" onclick="javascript:window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030502010199')" style="text-decoration: underline; cursor: pointer; margin-left: 20px;"><img data-v-8afd4414="" src="/images/beian.png">粤公网安备 44030002001835号</span>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="scss" scoped>
.footer-content {
  width: 100%;
  height: 250px;
  background: #222222;
  .info {
    color: #999999;
    // letter-spacing: 1px;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 11px;
  }
  .footer-info {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    padding-top: 40px;
    // border: 1px solid red;
    display: flex;
    .point {
      width: 10px;
      height: 10px;
      background-color: #1d953f;
      margin-bottom: 18px;
    }
    .footerL {
      width: calc(50% - 1px);
      height: 100%;
      //   border: 1px solid red;
      .footerL-item {
        display: flex;
        flex-direction: row;
        height: 100%;
        .footerL-1 {
          width: 60%;
          height: 100%;
          //   border: 1px solid rgb(25, 0, 255);
        }
        .footerL-2 {
          width: 40%;
          //   border: 1px solid rgb(25, 0, 255);
        }
      }
    }
    .footerC {
      width: 1px;
      height: 70%;
      background-color: #444444;
    //   border: 1px solid red;
    }
    .footerR {
      padding-left: 10%;
      width: calc(50% - 1px);
      height: 100%;
    //   border: 1px solid red;
      .footerR-item {
        height: 100%;
      }
    }
  }
  @media  screen  and (max-width: 1200px) {
    .footer-info {
      width: 100%;
    }
  }
}

dl {
  padding: 41px 10px 0 10px;
}
dl dt {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  line-height: 24px;
}
dl dd {
  padding-top: 20px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  line-height: 20px;
}
dl dd:hover {
  color: #fff;
}
.footerR dd:nth-of-type(1) {
  font-size: 16px;
  line-height: 24px;
  padding-bottom: 2px;
}
.footerR dd {
  font-weight: 300;
  padding-top: 10px;
}
.copyright {
  width: 100%;
  font-size: 13px;
  color: rgb(128, 126, 126);
  line-height: 55px;
  text-align: center;
  margin: 0 auto;
}
</style>
